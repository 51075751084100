<template>
  <div>
    <!-- Background circulos ////////////////// -->
    <div class="bg-cercles">
      <!-- Spinner loading -->
      <div class="bb-spinner" v-if="!listAvailable">
        <div class="bb-spiner__content">
          <div class="bb-spinner__dots">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <!-- Spinner loading -->

      <template v-else>
        <!-- Section Header Foto, nombre y mensaje ////////////////// -->
        <section class="list-header">
          <div class="list-header__avatar">
            <img
              v-show="myList.photo === null"
              src="../../assets/images/embarazada.jpg"
              width="108"
              height="108"
              srcset="
              ../../assets/images/embarazada-p-500.jpeg   500w,
              ../../assets/images/embarazada-p-800.jpeg   800w,
              ../../assets/images/embarazada-p-1080.jpeg 1080w,
              ../../assets/images/embarazada.jpg         1314w
            "
              sizes="(max-width: 479px) 100vw, 200px"
              alt
            />
            <img
              v-show="myList.photo !== null"
              :src="getImgUrl()"
              width="108"
              height="108"
              alt=""
            />
          </div>
          <div class="bb-container">
            <div class="list-header__head">
              <h1 class="list-header__name">
                {{ listTitle }}
              </h1>
              <div class="list-header__msg">
                {{ welcomeMessage }}
              </div>
              <div class="list-header__nav" v-show="funds && funds.length > 0">
                <TabNav :tabs="tabs" :selected="selected" @selected="setSelected" />
              </div>
            </div>
          </div>
        </section>

        <!--
        <section>
          <div v-if="isBetaUser">
            <adsense></adsense>
          </div>
        </section>
        -->

        <Tab :isSelected="selected === 'fondo'">
          <!-- Section Fondos economicos ////////////////// -->
          <div class="" v-show="funds && funds.length > 0">
            <div class="bb-container bb-container--lista">
              <div class="list-section">
                <div class="list-section__header">
                  <div class="bb-accordion__title">
                    <h3 class="header-3">Aportar a un fondo</h3>
                  </div>
                </div>

                <div class="list-section__content mar-b-3">
                  <p class="mar-b-2">
                    Hemos creado estos fondos para reunir dinero. Si quieres, puedes aportar
                    cualquier cantidad. ¡Toda ayuda es buena!
                  </p>
                  <div class="grid-fondos-cards">
                    <ListFondosCard
                      v-for="(fund, index) in funds"
                      :fund="fund"
                      :key="index"
                      :list="myList"
                    ></ListFondosCard>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Tab>

        <Tab :isSelected="selected === 'regalar'">
          <!-- Sección regalos ////////////////// -->
          <div class="" v-show="showProducts" id="price_range_filter">
            <div class="bb-container bb-container--lista">
              <div class="list-section">
                <div class="list-section__header">
                  <h3 class="header-3">Regalar un objeto</h3>
                </div>
                <div class="list-section__filters">
                  <price-range-filter class="mar-r-2"></price-range-filter>
                  <state-filter></state-filter>
                </div>
                <div v-if="hasHelloBBProduct" class="list-section__shopbb">
                  <div class="shopbb-features">
                    <div class="shopbb-features__title">
                      <img src="../../assets/img/logos/favicon.svg" alt="" />
                      ¡Cómpralo en la tienda de HelloBB!
                    </div>
                    <div class="shopbb-features__items">
                      <div class="shopbb-features__item">
                        {{ myList.ownerName }} acumulará un 5% de
                        todo lo que gastes.
                      </div>
                      <div class="shopbb-features__item">
                        {{ myList.ownerName }} podrá hacer
                        devoluciones sin problemas.
                      </div>
                      <div style="font-size: small;">
                        Recuerda que las compras en otras tiendas y las aportaciones económicas no cuentan.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-section__content" id="products_starting">
                  <div v-show="!productsIsNotEmpty">
                    No hay productos con los filtros aplicados
                  </div>
                  <div class="lpac-list">
                    <!--
                    <ListTips tip="acumular-pic" />
                    <ListTips tip="acumular-text" />
                    <ListTips tip="cambios-pic" />
                    <ListTips tip="cambios-text" />
                    -->
                    <template v-for="(product, index) in products">
                      <ListProductCardWAdd
                        :key="product.id"
                        :product="product"
                        :list="myList"
                        @bookedProductSelected="showModalBooked"
                        @updateList="refreshList"
                      ></ListProductCardWAdd>

                      <div v-if="shouldDisplayAds && getAdType(index) !== 'none'">
                        <p>Before ad</p>
                        <Adsense :adType="getAdType(index)"></Adsense>
                        <p>After ad</p>
                      </div>
                      <!-- Insert the tip component after every three products -->
                      <!--
                      <template
                        v-if="(index + 1) % 3 === 0 && product.status === 1 && hasHelloBBProduct"
                      >
                        <ListTips :key="index" :tip="tips[(((index + 1) - 3) / 3) % 4]" :list="myList" />
                      </template>
                      -->
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Tab>
        <div class="pad-3" v-show="!showProducts">
          <div class="bb-container bb-container--lista">
            <div class="list-empty">
              <img src="../../assets/img/ico/ico-gift-empty.svg" />
              <p>Parece que todavia no han añadido productos a la lista</p>
            </div>
          </div>
        </div>

        <!--    MODAL PRODUCTE RESERVAT-->
        <transition name="bbFace" @after-enter="showBookedModalContent = true">
          <div class="modal" v-show="showBookedModal" @click="close()">
            <transition name="bbUp" @after-leave="close()">
              <div v-show="showBookedModalContent" class="modal__card" @click.stop="">
                <div class="modal__dialog">
                  <div class="modal__header">
                    <div class="modal__back"></div>
                    <div class="modal__close">
                      <img
                        src="../../assets/img/ico/ico-close.svg"
                        alt="Cerrar"
                        @click="close()"
                      />
                    </div>
                  </div>
                  <div class="modal__content">
                    <div class="flux-regalo">
                      <div class="flux-regalo__title">
                        Alguien tiene reservado este regalo, ¿eres tú?
                      </div>

                      <div class="flux-regalo__msg">
                        Escribe el email con el que hiciste la reserva para acabar el
                        proceso de compra.
                      </div>

                      <div class="flux-regalo__form mb-0">
                        <div class="bb-form-item">
                          <div class="bb-form-label">Tu email</div>
                          <input
                            type="email"
                            class="bb-form"
                            placeholder="Escribe aquí tu email"
                            v-model.lazy="email"
                            :class="{
                            'bb-form--success': emailIsValid,
                            'bb-form--danger': emailHasError,
                          }"
                            required
                          />
                          <div class="bb-form-error" v-show="emailHasError">
                            Lo sentimos. Hay un error en este campo.
                          </div>
                          <div class="bb-form-error" v-show="this.error">
                            Lo sentimos. No hay ninguna reserva con este email
                          </div>
                        </div>
                        <loading-button v-if="loadingBooking"></loading-button>
                        <button
                          v-else
                          class="button button--primary button--block mar-t-1"
                          @click="next"
                          :disabled="disabled"
                        >
                          Acceder
                        </button>
                      </div>

                      <div class="flux-regalo__actions"></div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </transition>

        <transition name="bbFace" @after-enter="showSuccessModalContent = true">
          <div class="modal" v-show="showSuccessModal" @click="showContent = false">
            <transition name="bbUp" @after-leave="closeSuccessModal()">
              <div
                class="modal__card modal__card--mini"
                v-show="showSuccessModalContent"
                @click.stop=""
              >
                <div class="modal__dialog">
                  <div class="modal__header">
                    <div class="modal__close">
                      <img
                        src="../../assets/img/ico/ico-close.svg"
                        alt="Cerrar"
                        @click="showSuccessModalContent = false"
                      />
                    </div>
                  </div>
                  <div class="modal__content">
                    <div class="card-success">
                      <div class="card-success__ico">
                        <img
                          src="../../assets/img/ico/ico-color-success.svg"
                          alt="¡Gracias!"
                        />
                      </div>
                      <h3 class="card-success__header">¡Aportación realizada!</h3>
                      <div class="card-success__content">
                        Le hemos mandado un email a {{myList.ownerName}} para avisarle de tu
                        aportación. ¡Seguro que te lo agradece muchísimo!
                      </div>
                      <div class="card-success__actions">
                        <a
                          href="#"
                          @click="showSuccessModalContent = false"
                          class="button button--primary button--w60"
                          >Genial</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </transition>

        <!-- Sección CTA ////////////////// -->
        <div class="pad-3">
          <div class="bb-container">
            <CtaAmigos></CtaAmigos>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ListFondosCard from "./components/ListFondosCard.vue";
//import ListProductCard from "./components/ListProductCard.vue";
import ListProductCardWAdd from "./components/ListProductCardWAdd.vue";
import ListTips from "./components/ListTips.vue";
import CtaAmigos from "../cta/CtaAmigos.vue";
import { mapGetters } from "vuex";
import PriceRangeFilter from "./filters/PriceRangeFilter";
import StateFilter from "./filters/StateFilter";
import LoadingButton from "../../components/LoadingButton";
import CanGetGuestList from "../../Mixins/CanGetGuestList";
import GuestListFirstVisit from "../mixins/GuestListFirstVisit";
import Tab from "../../components/ui/tabs/Tab.vue";
import TabNav from "../../components/ui/tabs/TabNav.vue";
import HeaderList from "../../layout/HeaderList.vue";
import Adsense, {
  TYPE_NONE as NO_AD,
  TYPE_IMAGE_SIDE as AD_IMAGE_SIDE,
  TYPE_IMAGE_ABOVE as AD_IMAGE_ABOVE,
} from "../adsense/Adsense.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "ListAds2",
  mixins: [
    CanGetGuestList,
    GuestListFirstVisit,
  ],
  components: {
    HeaderList,
    ListFondosCard,
    //ListProductCard, // TODO: Eliminar cuando siguiente este lista
    ListProductCardWAdd, // TODO: Nueva tarjeta de producto
    CtaAmigos,
    ListTips,
    Adsense,
    "price-range-filter": PriceRangeFilter,
    "state-filter": StateFilter,
    "loading-button": LoadingButton,
    Tab,
    TabNav
  },
  computed: {
    ...mapGetters({
      guestProductFilters: "guestProductFilters",
      myList: "myList",
      shouldDisplayAds: "shouldDisplayAds",
    }),
    welcomeMessage() {
      return this.myList.welcomeMessage || this.DEFAULT_WELCOME_MESSAGE;
    },
    emailIsValid() {
      if (this.email === "") return false;
      return this.$utils.validateEmail(this.email);
    },
    emailHasError() {
      return !!(this.email !== "" && !this.$utils.validateEmail(this.email));
    },
    showProducts() {
      if (this.guestProductFilters?.length > 0) return true;
      return this.productsIsNotEmpty;
    },
    productsIsNotEmpty() {
      return this.products && this.products.length > 0;
    },
    disabled() {
      return !this.$utils.validateEmail(this.email);
    },
    listTitle() {
      return this.myList.title || "Lista de " + this.myList.ownerName;
    },
    title() {
      return this.myList.title;
    },
    funds() {
      return this.myList.funds || [];
    },
    products() {
      let products = this.myList.products || [];

      if (products.length > 0) {
        if (this.guestProductFilters) {
          this.guestProductFilters?.forEach((filter) => {
            if (filter.name === "state" && filter.values?.id !== 99) {
              products = products.filter((product) => product.status == filter.values.id);
            }
            if (filter.name === "priceRange") {
              products = products.filter((product) => {
                const minPrice = this.minPrice(product);
                return (
                  minPrice >= filter.values.priceRange.min &&
                  minPrice <= (filter.values.priceRange.max || 9999999)
                );
              });
            }
          });
        }
      }

      return products;
    },
    // isBetaUser() {
    //   return this.myList.userId === 366;
    // },
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [{ name: "robots", content: "noindex" }]
    };
  },
  data() {
    return {
      toggleFund: true,
      showBookedModal: false,
      showBookedModalContent: false,
      showSuccessModal: false,
      showSuccessModalContent: false,
      email: "",
      selectedProduct: null,
      error: false,
      reservation: null,
      loadingBooking: false,
      listAvailable: false,
      hasHelloBBProduct: null,
      selected: "regalar",
      tabs: [
        {
          key: "regalar",
          name: "Regalar un objeto",
          ico: "uil-baby-carriage"
        },
        {
          key: "fondo",
          name: "Aportar a un fondo",
          ico: "uil-coins"
        }
      ],
      tips: ["acumular-pic", "acumular-text", "cambios-pic", "cambios-text"]
    };
  },
  methods: {
    async getList(server = false, id = null) {
      return await this.$store.dispatch("getListGuest", {
        id: id ? id : this.$route.params.id,
        server
      });
    },
    refreshList() {
      this.getList(false, this.$route.params.id);
      //scroll to top
      window.scrollTo(0, 0);
    },
    async getListFromServer() {
      return await this.getList(true);
    },
    getId(product) {
      return product.isFree ? "free_" + product.id : "catalogue_" + product.id;
    },
    close() {
      this.showBookedModal = false;
      this.error = false;
      document.body.classList.remove("modal-open");
      document.getElementById(this.getId(this.selectedProduct)).scrollIntoView();
    },
    async checkEmail() {
      let result;
      if (this.selectedProduct.isFree) {
        result = await this.$store.dispatch("checkEmailFreeProduct", {
          email: this.email,
          product: this.selectedProduct
        });
      } else {
        result = await this.$store.dispatch("checkEmail", {
          email: this.email,
          product: this.selectedProduct
        });
      }

      if (result?.data) {
        this.reservation = result.data;
        return true;
      }
      return false;
    },
    async next() {
      this.loadingBooking = true;
      let result = false;
      result = await this.checkEmail();
      this.loadingBooking = false;
      if (result) {
        this.error = false;
        document.body.classList.remove("modal-open");
        this.$router.push({
          name: "guestProduct",
          params: {
            id: this.myList.id,
            productId: this.selectedProduct.isFree
              ? this.selectedProduct.id
              : this.selectedProduct.listProductId,
            //productType: this.selectedProduct.isFree ? "free" : "catalog",
            productType: this.selectedProduct.isFree
              ? "free"
              : this.selectedProduct.retailerName != "HelloBB"
                ? "catalog"
                : "hellobb"
          },
          query: this.reservation
        });
      }
      this.error = true;
    },
    showModalBooked(product) {
      this.selectedProduct = product;
      document.body.classList.add("modal-open");
      this.showBookedModal = true;
    },
    minPrice(product) {
      if (product.isFree) return product.price;
      return Math.min(
        ...product.productRetailers.map((productRetailer) => productRetailer.price)
      );
    },
    getImgUrl() {
      if (this.myList.photo == "" || this.myList.photo == null) {
        return "#";
      }
      return process.env.URL_IMG_USER + this.myList.photo;
    },
    setSelected(tab) {
      this.selected = tab;
    },
    openSuccessModal() {
      document.body.classList.add("modal-open");
      this.showSuccessModal = true;
    },
    closeSuccessModal() {
      document.body.classList.remove("modal-open");
      this.showSuccessModal = false;
    },
    getFundIdFromStatus(status) {
      const prefix = "success-fund-";
      if (status.indexOf(prefix) !== 0) {
        return null;
      }

      const rawFundId = status.substring(prefix.length);
      const fundId = this.$utils.strictParseInt(rawFundId);

      return fundId;
    },
    getAdType(listItemIndex) {
      let i = listItemIndex;

      const AD_TYPES = [
        AD_IMAGE_SIDE,
        AD_IMAGE_ABOVE,
      ];

      let adType = 0;
      function advance(n) {
        i -= n;
        adType = (adType + 1) % AD_TYPES.length;
      }

      // Show ad after the first item.
      if (i === 0) {
        return AD_TYPES[adType];
      }

      // In the code below, `i` will become a negative value a few times, but
      // that shouldn't be a problem.
      //
      // And the few extra calculations shouldn't be noticeable at all.

      advance(3);
      if (i === 0) {
        return AD_TYPES[adType];
      }

      advance(5);
      if (i === 0) {
        return AD_TYPES[adType];
      }

      advance(2);
      if (i === 0) {
        return AD_TYPES[adType];
      }

      // We can just use division, but this loop is less error-prone because
      // there's no math involved.
      do {
        advance(5);
      } while (i > 0);

      if (i === 0) {
        return AD_TYPES[adType];
      }

      return NO_AD;
    },
  },
  created() {
    const filtersQS = this.$route.query.filters;
    if (typeof filtersQS === "string" || filtersQS instanceof String) {
      const filters = JSON.parse(filtersQS);
      this.$setGuestProductFilters(filters, false);
    } else {
      this.$setGuestProductFilters([], false, false);
    }

    const productsWithHelloBB = this.products.some(
      product => product.retailerName === "HelloBB"
    );

    if (productsWithHelloBB) {
      this.DEFAULT_WELCOME_MESSAGE =
        "¡Hola! Hemos creado esta lista con cosas que nos hacen falta para cuando llegue nuestro bebé. Ten en cuenta que si compras directamente en HelloBB, acumularemos dinero y no tendremos problemas con posibles devoluciones. ¡Muchas gracias!";
    } else {
      this.DEFAULT_WELCOME_MESSAGE =
        "¡Hola! Hemos creado esta lista con cosas que nos hacen falta para cuando llegue nuestro bebé. ¡Muchas gracias!";
    }
  },
  beforeMount() {
    const escapeHandler = (e) => {
      if (e.key === "Escape") {
        this.close();
      }
    };
    document.addEventListener("keydown", escapeHandler);
    // eslint-disable-next-line vue/no-deprecated-events-api
    this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", escapeHandler);
    });
  },
  async serverPrefetch() {
    return await this.getListFromServer();
  },
  async mounted() {
    this.hasHelloBBProduct = this.products.some(product => product.retailerName === "HelloBB");
    const preview = this.$route.query.preview;
    const source = this.$route.query.source;
    const vista = this.$route.query.vista;

    if (this.hasHelloBBProduct && this.isFirstTime && !preview && !vista && !source) {
      this.$router.push({ path: `/guest/${this.$route.params.id}/1` });
    }

    if (!this.myList) {
      await this.getList();
    } else {
      if (!this.myList.server) {
        await this.getList();
      }
    }

    this.listAvailable = true;

    if (this.guestProductFilters?.length > 0) this.loading = false;

    window.mixpanel.identify(this.myList.userId);
    window.mixpanel.track("Visita llista compartida", {
      "List name": this.myList.title,
      "List owner": this.myList.ownerName,
      "List URL": "https://www.hellobb.net/lista/guest/" + this.myList.id
    });

    //add to adsense-container the adsense script
    //const adsenseScript = document.createElement("script");
    //adsenseScript.setAttribute("async");
    //adsenseScript.setAttribute("src", "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7098941501180575");
    //adsenseScript.setAttribute("crossorigin", "anonymous");
    //document.getElementById("adsense-container").appendChild(adsenseScript);

    //execute only if there is a hash in the url
    if (window.location.hash == "#products_starting") {
      this.$nextTick(() => {
        // Get the offset from the top of the "products_starting" section
        //const headerHeight = document.querySelector('.list-header').offsetHeight;
        const headerHeight = 80;
        const section = document.getElementById("products_starting");

        if (section) {
          const offset = section.offsetTop - headerHeight;

          // Scroll to the section with smooth behavior
          window.scrollTo({ top: offset, behavior: "smooth" });
        }
      });
    }


    //execute only if there is a status value in the querystring
    let status = this.$route.query.status;
    if (status) {
      if (status.includes("success")) {
        const fundId = this.getFundIdFromStatus(status);

        const fund = this.myList.funds.find((fund) => fund.id === fundId);
        if (fund) {
          this.$nextTick(() => {
            this.openSuccessModal();
          });
        } else {
          this.$store.commit("setErrorMessage", "Lo sentimos, ha habido un error al realizar la aportación.");
          this.closeSuccessModal();
        }
      } else if (status.includes("cancelled")) {
        this.closeSuccessModal();
      }

      let id = status.split("-")[2];
      let type = status.split("-")[1];
      this.$nextTick(() => {
        const headerHeight = 80;
        const section = document.getElementById(type + "_" + id);
        if (section) {
          const offset = section.offsetTop - headerHeight;
          window.scrollTo({ top: offset, behavior: "smooth" });
        }
      });
    }
  }
};
</script>
<style scoped></style>
